import React, { Component } from 'react';
import { EditorContext } from "../page-editor";
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from "../../actions";
import { paths } from "../../router";
import { commands } from "../../lib/inline-editor";
import { FRONTEND_DATA } from "../../globals";
import { HotKey } from "../ui-kit";
import { TooltipContext } from "@cargo/common/tooltip";
import _ from 'lodash';

import formattingWindowImports from './formatting-ui-windows/imports';

class FormattingButton extends Component {

	constructor(props) {
		
		super(props);

		this.uiWindowID = `formatting/${this.props.commandName}`;
		this.buttonRef = React.createRef();

		this.state = {
			buttonPos: {
				x: null,
				y: null,
				width: null,
				height: null
			},
			awaitingInitTimeout: true
		}

		requestAnimationFrame(() => {
			this.setState({
				awaitingInitTimeout: false
			})
		});

	}

	render() {

		const { className, activeWindow, tooltip, toolTipIcon, data, } = this.props;
		const correspondingUIWindowIsOpen = _.get(activeWindow, 'id') === this.uiWindowID;
		const classList = `formatting-button ${className !== undefined ? className : ''}${correspondingUIWindowIsOpen ? ' popover-open' : ''}`;

		this.buttonState = this.getButtonState();

		// Assign variables for override below.
		let label = this.props.label,
			buttonState = this.buttonState;

		if (typeof this.props.retrieveButtonState === 'function') {
			this.props.retrieveButtonState(buttonState);
		}
	
		if( this.props.data?.type == 'button-context-menu' ){
			// Re-check button state to see if the range contains an in-use menu child. 
			let activeCommand = this.getActiveCommand();

			// If it does, get the SVG and manually override button state and displayed icon
			if( activeCommand !== null && activeCommand ){
				label = _.get(activeCommand, 'label');
				buttonState = 'in-use';
			}
		}

		return (
			<>
				<TooltipContext.Consumer>
				    {(Tooltip) => 
						<button
							className      = { classList }
							button-state   = { this.buttonState }
							onMouseDown  = { (e) => { 
								this.onMouseDown(e); 

								Tooltip.closeTip?.(e); 

								this.callCommand(e)
							}}
							onPointerEnter = { (e) => {
								let buttonPos = this.buttonRef.current.getBoundingClientRect();
								if( correspondingUIWindowIsOpen ){ return }

								Tooltip.openTip?.({
								    innerText: this.props.tooltip,
								    shortcutText: null,
								    icon: toolTipIcon,
								    buttonPos: {
								    	x: buttonPos.x,
								    	y: buttonPos.y,
								    	width: buttonPos.width,
								    	height: buttonPos.height,
								    },
								    event: e,
								})

							}}
							onPointerLeave = { (e) => Tooltip.closeTip?.(e) }
							button-command = { this.props.commandName }
							ref            = { this.buttonRef }
							title          = { this.props.title }
							style          = { this.props.style }
							type           = { this.props.type }
							id             = { this.props.id }
						>
							{ label }
						</button>
					}
				</TooltipContext.Consumer>

				{this.props.data?.shortcut ? 
					<HotKey 
						boundTo="editor"
						shortcut={this.props.data.shortcut}
						callback={(e) => {
							this.callCommand(e);
					}}/>
				: null }

				{this.props.data?.type === 'button-context-menu' && _.get( this.props.data, 'options') ? 
					this.props.data.options.map((option)=> option.shortcut && <HotKey 
							key={option.shortcut}
							boundTo="editor"
							shortcut={option.shortcut}
							callback={(e) => {
								this.callCommand(e, option.name);
						}}/>
					)
				: null }
			</>
		);

	}

	onMouseDown = (e) => {
		if (typeof this.props.onMouseDown === 'function') {
			this.props.onMouseDown(e);
		}
		// prevent default so focus is not lost in the editor
		e.preventDefault();
	}

	getButtonState() {

		// FORMATTING BUTTON STATE NOMENCLATURE
		// applied 		= a single option that is either applied to the range or not
		// in-use 		= the selection is using or is within something that requires additional decisions (via uiWindow or menu) 
		// unavailable 	= the range cannot support this formatting option
		// available 	= the range supports this option, but is not in-use or is not applied
		const isEditingPage = _.get(this.props.matchedRoute, 'params.pid') !== undefined;
		const command       = commands[this.props.commandName];

		if (this.props?.type === 'hidden') {
			return 'hidden';
		}

		// FALLBACK — fallback condition if we render without a range or an editor
		if( !isEditingPage || !command || !this.context.range ) {
			return 'unavailable';
		}

		if( !this.context.range.commands[this.props.commandName] ){
			return 'unavailable';
		}

		let commandIsApplied = false;

		if( this.context.range.commands[this.props.commandName].isApplied ){
			commandIsApplied = true
		}

		if( this.props.alternateCommand ){
			_.each( this.props.alternateCommand, (command) => {
				if( this.context.range.commands[command].isApplied ){
					commandIsApplied = true;
				}
			})
		}

		const commandIsAllowed = this.context.range.commands[this.props.commandName].isAllowed;

		// APPLIED / IN USE
		// command is currently applied to the selected range
		if( commandIsApplied && commandIsAllowed && !command.ignoreButtonInUseState) {
		 	return 'in-use';

		// AVAILABLE / CAN BE USED
		} else if (!commandIsApplied && commandIsAllowed) {
			return 'available';
		}

		// DEFAULT = UNAVAILABLE
		return 'unavailable';

	}

	getActiveCommand = () => {

		// Re-check if we're editing a page.
		const isEditingPage = _.get(this.props.matchedRoute, 'params.pid') !== undefined;

		// Return if we're not editing a page and/or don't have an active range.
		if( !isEditingPage || !this.context.range ) {
			return null;
		}

		let commands = _.get( this.props.data, 'options'),
			activeCommand = null;
		// Iterate through commands & check state
		_.each( commands, ( command ) => {
			// If the command is applied by the section
			if( this.context.range.commands[command.command]?.isApplied ){
				// we've got a match.
				activeCommand = command
			}
		});

		// Return active command
		return activeCommand;
	}

	callCommand = (e, passedCommand) => {
		// prevent default so focus is not lost in the editor
		e.preventDefault();
		// do nothing if the button is available
		if( this.buttonState === 'unavailable') return;
		// hidden buttons don't need to be setup

		if (this.props.data?.disabled === true) {
			alert('Coming soon')
			return;
		}

		let suppressWindow = false;
		const {menu, uiWindow} = this.props,
			  commandName = passedCommand ? passedCommand : this.props.commandName,
			  command = commands[commandName],
			  commandState = this.context.range.commands[commandName] || {},
			  buttonHasWindowMenu = ( menu || uiWindow ) && this.props.data.type !== 'button-context-menu';
		// Command Execution
		if(	command 
			&& command.execute 
			&& commandState.isAllowed 
			&& this.props.data.type !== 'button-context-menu'
		) {
				// execute the command
				command.execute();

				// suppress the window after executing, but only for first execution
				if ( !commandState.isApplied ){
					suppressWindow = command.suppressWindowAfterExecution ? true : false;	
				}
				
		}

		// Window Opening: if the button has an associated window that is not suppressed, open it
		if ( commandState.isAllowed && buttonHasWindowMenu && !suppressWindow ) {

			const windowImport = formattingWindowImports[this.props.commandName]?.();

			if(!windowImport) {
				console.error(`Unable to find window with name "${options.windowAlias ? options.windowAlias : options.windowName}"`);
				return;
			}

			this.props.addUIWindow({
				group: 'formatting',
				component: windowImport,
				id: this.uiWindowID,
				props: {
					type         		: 'popover',
					buttonPos    		: _.merge({}, this.buttonRef.current.getBoundingClientRect()),
					windowName   		: this.props.commandName       ? `${this.props.commandName}` 		: null,
					autoHeight   		: this.props.autoHeight        ? `${this.props.autoHeight}`			: null,
					positionType 		: this.props.data.positionType ? `${this.props.data.positionType}` 	: null,
					borderRadius 		: this.props.data.borderRadius ? `${this.props.data.borderRadius}` 	: null,
					maxHeight    		: this.props.data.maxHeight    ? `${this.props.data.maxHeight}`    	: null,
					closeOnAllClickout 	: this.props.data.closeOnAllClickout ? true 						: null,
					closePrioritizedByCommand : this.props.data.closePrioritizedByCommand ? this.props.data.closePrioritizedByCommand : null,
					waitForHeightBeforeRender: true,
					minimumRenderHeight: 50,
				}
			}, {
				removeGroupByName: 'right-menu-bar',
			});

		}

	}

	componentDidMount(){
		this.closeWindowIfButtonIsUnavailable();
	}

	componentDidUpdate(prevProps){
		this.closeWindowIfButtonIsUnavailable();
	}

	closeWindowIfButtonIsUnavailable = () => {

		// when resuming the editor, wait a moment for everything to initialize
		// before checking if the window should be removed or not
		if(this.state.awaitingInitTimeout) {
			return;
		}

		if( this.buttonState !== 'unavailable' || !commands[this.props.commandName]){
			return;
		}

		if( this.props.closeIfUnavailable 
			&& this.props.activeWindow?.props.windowName === this.props.commandName
		) {
			this.props.removeUIWindow( `formatting/${this.props.commandName}` )
		}

	}

};

FormattingButton.contextType = EditorContext;

function mapReduxStateToProps(state, ownProps) {
	return {
		matchedRoute: state.adminState.matchedRoute,
		activeWindow: _.map(state.uiWindows.byGroup['formatting'], uiWindowID => state.uiWindows.byId[uiWindowID])[0]
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		updateAdminState: actions.updateAdminState,
		addUIWindow: actions.addUIWindow,
		removeUIWindow: actions.removeUIWindow
	}, dispatch);
}

export default connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(FormattingButton);
